#login-section{
  background: url('../../images/login-bg.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  height: 100vh;
  width: 100%;
  padding-top: 75px;
  h1,h2,h3{
    font-weight: 700;
  }
  .form-area{
    &.step-one{
      width: 450px;
      height: 414px;
      background: url('../../images/login-art.svg') $white;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 15px;
      margin: auto;
      padding: 15px;
      position: relative;
      input[type='text']{
        margin-bottom: 15px;
      }
      h1{
        font-size: 32px;
        text-align: right;
        color: $dark-gray;
        padding: 0 15px;
        margin: 0 0 25px;
      }
    }
    &.step-two{
      width: 450px;
      height: 414px;
      background: url('../../images/create-newteam-bg.svg') $white;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 15px;
      margin: auto;
      padding: 15px;
      position: relative;
      h1{
        width: 75%;
        font-size: 30px;
        text-align: right;
        color: $dark-gray;
        padding: 0 15px;
        margin: 0 0 25px;
        float: right;
        span{
          color: $secondary-blue;
          font-weight: 700;
        }
      }
    }
    &.step-three{
      width: 450px;
      height: 414px;
      background: url('../../images/step-3.svg') $white;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 15px;
      margin: auto;
      padding: 15px;
      position: relative;
      h1{
        width: 60%;
        font-size: 36px;
        text-align: right;
        color: $dark-gray;
        padding: 0 15px;
        margin: 0 0 25px;
        float: right;
      }
      .scroll-area{
        max-height: 131px;
        width: 420px;
        overflow-y: hidden;
        position: relative;
        background-color: $white;
        .content {
          display: inline-block;
          width: inherit;
          margin-bottom: 2px;
        }
      }
    }
    &.step-five{
      width: 450px;
      height: 414px;
      background: url('../../images/step-4.svg') $white;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 15px;
      margin: auto;
      padding: 25px 35px 15px;
      position: relative;

      .scroll-area{
        height: 250px;
        overflow-y: hidden;
        position: relative;
        background-color: $white;

      }
      h1{
        width: 100%;
        font-size: 25px;
        color: $dark-gray;
        padding:10px;
        margin: 0 0 15px;
        background: $white;
      }
      ul{
        list-style-type: none;

        li{
          margin-bottom: 10px;
        }
      }
    }
    &.step-four{
      width: 450px;
      height: 414px;
      background: url('../../images/step-4.svg') $white;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 15px;
      margin: auto;
      padding: 25px 35px 15px;
      position: relative;
      .form-group{
        padding: 8px 20px;
        background: $white;

        border-radius: 4px;
        table{
          width: 100%;
          tr{
            // &:first-child{
            //   border-bottom: 1px solid darken($white, 5);
            // }

            td {
              padding: 8px 6px;
              &:first-child{
                width: 130px;
                font-weight: 700;
                color: $red;
              }
            }
          }
        }
      }
      .h4{
        font-size: 1rem;
        font-weight: 700;
        margin: 0;
        color: $secondary-blue;
      }
      p{
        margin: 0;
      }
      h1{
        width: 75%;
        font-size: 36px;
        text-align: right;
        color: $dark-gray;
        padding: 0 15px;
        margin: 0 0 15px;
        float: right;
      }
      .separator{
        width: 75%;
        height: 10px;
        background-color: $primary-color;
        float: right;
        display: block;
        margin-bottom: 15px;
      }
      h2{
        color: lighten($black, 70);
        width: 75%;
        font-size: 28px;
        text-align: right;
        padding: 0 15px;
        margin: 0 0 15px;
        float: right;
      }
      h3{
        width: 100%;
        font-size: 32px;
        text-align: right;
        color: $secondary-blue;
        padding: 0 15px;
        margin: 0 0 15px;
        float: right;
      }
    }
  }
  .previous-step{
    width: 24px;
  }
  .logo{
    width: 100px;
    margin: 0 auto 20px;
    display: block;
  }


  input{
    &.team-name, &.email-address,{
      height: 46px;
      padding: 10px 25px;
      border-radius: 40px;
      width: 85%;
      background: $bg--gray;
      float: right;
      border: 1px solid lighten($gray, 30);
      outline: 0;
      font-size: 16px;
      margin-right: 15px;
    }
    &.email-address{
      float: none;
      margin: auto;
      display: block;
    }
  }
  .entry-button{
    background-color: $secondary-blue;
    padding: 12px 25px;
    border-radius: 50px;
    position: absolute;
    bottom: 25px;
    right: 25px;
    font-weight: $bold;
    color: $white;
    border: none;
    outline: 0;
    line-height: 16px;
    display: flex;
    cursor: pointer;
    &:hover{
      background-color: lighten($secondary-blue , 10);
    }
    img{
      width: 14px;
      margin-left: 10px;
    }
    span{
      font-size: 20px;
    }
  }

  .back-button{
    background-color: $black;
    padding: 12px 25px;
    border-radius:50px;
    position: absolute;
    bottom: 25px;
    left: 25px;
    font-weight: $bold;
    color: $white;
    border: none;
    outline: 0;
    line-height: 16px;
    display: flex;
    cursor: pointer;
    &:hover{
      background-color: lighten($secondary-blue , 10);
    }
    img{
      width: 14px;
    }
  }

  .confirm-button{
    background-color: $green;
    padding: 12px 25px;
    border-radius: 50px;
    position: absolute;
    bottom: 25px;
    right: 25px;
    font-weight: $bold;
    color: $white;
    border: none;
    outline: 0;
    line-height: 16px;
    display: flex;
    cursor: pointer;
    &:hover{
      background-color: lighten($green , 10);
    }
    img{
      width: 14px;
    }
  }

  button:disabled{
    background-color: $gray;
    &:hover{
      background-color: darken($gray , 10);
    }
  }

  .end-button{
    background-color: $red;
    padding: 12px 25px;
    border-radius: 50px;
    position: absolute;
    bottom: 25px;
    right: 25px;
    font-weight: $bold;
    color: $white;
    border: none;
    outline: 0;
    line-height: 16px;
    min-width: 100px;
    text-align: center;
    cursor: pointer;
    &:hover{
      background-color: lighten($red , 10);
    }
    img{
      width: 14px;
    }
  }

  .add-more{
    background-color: transparent;
    padding: 5px;
    border:none;
    margin-top: 10px;
    display: flex;
    outline: 0;
    cursor: pointer;
    float: right;
    margin-right: 35px;
    line-height: 12px;
    font-size: 13px;
    img{
      width: 12px;
      margin-left: 5px;
    }
  }
}

.login--screen{
  background: url('../../images/boomea-bg.jpg');
  background-repeat: no-repeat;
  display: flex;
  height: 100vh;
  .boomea-logo{
    position: absolute;
    left: 0;
    top: 0;
    padding: 20px 40px;
    img{
      width: 240px;
    }
  }
  .login-form-container{
    width: 400px;
    height: 400px;
    margin: 150px auto 0;
    .widget__title {
      background-color: $primary-color;
      padding: 30px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      h2{
        color: $white;
        margin: 0;
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }
    .trasperant__bg {
      border-top: 2px solid $white;
      background-color: rgba(255,255,255,0.08);
      padding: 30px 30px 40px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .forgot-password{
        margin-top: 15px;
        display: block;
        color: $primary-color;
      }
      input {
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 20px center;
        height: 3.57143em;
        border-radius: 35px;
        padding: 10px 15px 10px 50px;
        background-color: rgba(255,255,255,0.08);
        border-color: rgba(255,255,255,0.2);
        color: $white;
        &::-webkit-input-placeholder { /* Edge */
          color: #ccc;
        }

        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #ccc;
        }

        &::placeholder {
          color: #ccc;
        }
        &#username{
          background-image: url('../../images/user.png');
        }
        &#password{
          background-image: url('../../images/lock.png');
        }
        &:focus{
          box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(102,175,233,0.6);
        }
      }

      .btn {
        font-size: 1em;
        font-weight: 700;
        margin-right: 5px;
        padding: .5em 1.07143em;
        height: 3.57143em;
        border-radius: 35px;
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        display: block;
        &.btn-primary {
          background: $primary-color;
          border-color: transparent;
          &:hover{
            background-color: darken($primary-color, 10)
          }
        }
      }
    }
  }
}
